"use client";
import { FavouriteIcon } from "@/components/icon";
import styles from "./header-actions-favourite.module.scss";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "@/services/redux/hooks";
import {
  getAuthStatus,
  getIsNewUser,
  getUserInfo,
} from "@/services/redux/features/userSlice";
import { useRouter } from "next/navigation";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { AuthPopup } from "@/components/popups";

const HeaderActionsFavourite: FC = () => {
  const countFavourites: number =
    useAppSelector(getUserInfo).info.favourites.length;
  const router = useRouter();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const handleClick = () =>
    isAuthUser ? router.push("/cabinet/favourites") : handleOpenModal();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  const isNewUser = useAppSelector(getIsNewUser);
  
  return (
    <>
      {showModal && <AuthPopup isOpen={showModal} onClose={handleCloseModal} />}
      {/* {isNewUser && (
        <WelcomeBonusPopup />
      )} */}
      <div className={styles.wrap}>
        <button onClick={handleClick} className={styles.button} title="Избранное">
          <FavouriteIcon />
          <span className={styles.count}>{isClient ? countFavourites : 0}</span>
        </button>
      </div>
    </>
  );
};
export default HeaderActionsFavourite;
