import { ICardMini } from "@/services/types/types";
import styles from "./search-card.module.scss";
import { FC } from "react";
import Image from "next/image";
import { Stars } from "@/components/stars/stars";
import { ChevronIcon } from "@/components/icon";
import { priceWithSpaces } from "@/utils/functions/price-with-spaces";
import NoPhotoImg from "../../../../../../assets/images/no-photo.webp";
import Link from "next/link";

interface ISearchCard {
  card: ICardMini;
  onClose: () => void;
}

export const SearchCard: FC<ISearchCard> = (props) => {
  const { card, onClose } = props;
  return (
    <Link
      prefetch={false}
      href={`/card/${card.id}/`}
      className={styles.wrap}
      onClick={onClose}
    >
      <div className={styles.left_side}>
        <Image
          alt={card.name}
          src={
            card.preview_picture
              ? `https://ohotaktiv.ru${card.preview_picture}`
              : NoPhotoImg
          }
          height={200}
          width={200}
          className={styles.image}
        />
        <div className={styles.info}>
          <Stars className={styles.reviews} stars={card.reviews} />
          <p className={styles.name}>{card.name}</p>
        </div>
      </div>
      <div className={styles.right_side}>
        <div className={styles.prices}>
          {card.price_discount !== card.price ? (
            <>
              {card.comission && <p className={styles.price}>{priceWithSpaces(card.price)}</p>}
              {card.price_discount && <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
              {card.comission !== true && <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>}
            </>
          ) : (
            <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>
          )}
        </div>
        <ChevronIcon className={styles.chevron} />
      </div>
      {/* <div className={styles.head}>
        <Image
          alt={card.name}
          src={
            card.preview_picture
              ? `https://ohotaktiv.ru${card.preview_picture}`
              : NoPhotoImg
          }
          height={200}
          width={200}
          className={styles.image}
        />
      </div>
      <div className={styles.info}>
        <Stars className={styles.reviews} stars={card.reviews} />
        <p className={styles.name}>{card.name}</p>
      </div>
      <div className={styles.bottom}>
        <div className={styles.prices}>
          {card.price_discount !== card.price ? (
            <>
              {card.comission && <p className={styles.price}>{priceWithSpaces(card.price)}</p>}
              {card.price_discount && <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>}
              {card.comission !== true && <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>}
            </>
          ) : (
            <p className={styles.price}>{priceWithSpaces(card.price_discount)}</p>
          )}
        </div>
        <div className={styles.actions}>
          <CardMiniButtons card={card} onClickAddToCart={() => { }} />
          <button className={styles.favourite}>
            <FavouriteIcon className={styles.icon} />
          </button>
        </div>
      </div> */}
    </Link>
  );
};