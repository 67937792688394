import { SearchIcon } from "@/components/icon";
import styles from "./search-popular.module.scss";
import { FC } from "react";

interface ISearchPopular {
  keys: string[];
  onClickPopular: (value: string) => void;
}

export const SearchPopular: FC<ISearchPopular> = ({ keys, onClickPopular }) => {
  return (
    keys.length > 0 && (
      <div className={styles.wrap}>
        <p className={styles.title}>Часто ищут</p>
        <div className={styles.buttonList}>
          {keys.map((key, index) => {
            if(index > 2) return
            return (
              <div key={index + "SearchPopular" + key} className={styles.buttonWrap}>
                <button
                  className={styles.button}
                  onClick={() => onClickPopular(key)}
                >
                  <SearchIcon className={styles.icon} />
                  <p className={styles.buttonText}>{key.replace(/[+]/g, " ")}</p>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};
