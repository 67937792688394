import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";
import styles from "./search-modal-skeleton.module.scss";
import { FC } from "react";

export const SearchModalSkeleton: FC = () => {
  return (
    <div className={styles.wrap}>
      <SkeletonLoader
        containerTag="div"
        height={24}
        borderRadius={8}
        width="100%"
        mb={8}
      />
      <SkeletonLoader
        containerTag="div"
        height={24}
        borderRadius={8}
        width="100%"
        mb={8}
      />
      <SkeletonLoader
        containerTag="div"
        height={24}
        borderRadius={8}
        width="100%"
        mb={16}
      />

      <SkeletonLoader
        containerTag="div"
        height={32}
        borderRadius={8}
        width="100%"
        mb={8}
      />
      <SkeletonLoader
        containerTag="div"
        height={32}
        borderRadius={8}
        width="100%"
        mb={8}
      />
      <SkeletonLoader
        containerTag="div"
        height={32}
        borderRadius={8}
        width="100%"
        mb={16}
      />

      <SkeletonLoader
        containerTag="div"
        height={60}
        borderRadius={8}
        width="100%"
        mb={12}
      />
      <SkeletonLoader
        containerTag="div"
        height={60}
        borderRadius={8}
        width="100%"
      />

      <SkeletonLoader
        containerTag="div"
        height={60}
        borderRadius={8}
        mt={24}
        width={180}
        ml={'auto'}
        mr={'auto'}
      />
    </div>
  );
};