import Link from "next/link";
import styles from "./cart-menu-button.module.scss";
import { FC, useEffect, useState } from "react";
import { CartIcon } from "@/components/icon";
import { useAppSelector } from "@/services/redux/hooks";
import { getUserInfo } from "@/services/redux/features/userSlice";

interface ICartMenuButton {}

export const CartMenuButton: FC<ICartMenuButton> = () => {
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter((item) => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return (
    <Link prefetch={false} href="/cart/" className={styles.button}>
      <CartIcon />
      Корзина
      <span className={styles.count}>{isClient ? countCart : 0}</span>
    </Link>
  );
};
