"use client";
import { SearchIcon } from "@/components/icon";
import styles from "./header-search.module.scss";
import { FC, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { SearchContextProvider } from "@/services/contexts/searchContext";
import useDebouncedFunction from "@/services/hooks/useDebouncedFunction";
import { useAppDispatch } from "@/services/redux/hooks";
import SearchModal from "./search-modal/search-modal";
import { getSearchItems } from "@/utils/api/search";
import { ICardMini } from "@/services/types/types";
import { setSearch } from "@/services/redux/features/searchSlice";
import { usePathname, useRouter } from "next/navigation";

interface IHeaderSearch {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const HeaderSearch: FC<IHeaderSearch> = forwardRef((props, ref?: Ref<any>) => {
    const {isOpen, setIsOpen} = props;
    
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapRef = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const pathName = usePathname();
    // поисковая выдача
    const [searchItems, setSearchItems] = useState<Array<ICardMini>>([]);
    // поисковые ключи
    const [searchKeys, setSearchKeys] = useState<string[]>([]);
    // поисковый редирект если есть
    const [searchRedirect, setSearchRedirect] = useState<string>("");
    // поисковый запрос
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchCatalogs, setSearchCatalogs] = useState<
      {
        [key: string]: string | number;
        sort: number
      }[]
    >([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    // const [isOpenVoiceModal, setIsOpenVoiceModal] = useState<boolean>(false)
    // const voiceModalRef = useRef<HTMLDivElement>(null);
    
    const debouncedSearch = useDebouncedFunction((value) => {
      // const params = new URLSearchParams(searchParams.toString());
      setIsFetching(true);
      setSearchQuery(value);
      if (value.length !== 0) {
        getSearchItems(value).then((res) => {
          setSearchItems(res.items);
          setSearchKeys(res.keys);
          setSearchCatalogs(res.catalog);
          dispatch(setSearch(value));
          setSearchRedirect(res.redirect);
          ym(42989679, "reachGoal", "useSearch");
          setIsFetching(false);
          // const valueWithSymbols = value.replace(/ /g, "+");
          // params.set("search", valueWithSymbols);
          // window.history.pushState(null, "", `?${params.toString()}`);
        });
      }
      // else {
      // params.delete("search");
      // window.history.pushState(null, "", `?${params.toString()}`);
      // }
    }, 333);
    
    const handleSearchInput = (arg: string) => {
      // setTranscriptValue(arg)
      debouncedSearch(arg);
    };
    
    const handlePopularClick = (value: string) => {
      if (!inputRef.current) return;
      inputRef.current.value = value;
      debouncedSearch(value);
    };
    
    // useEffect(() => {
    //   const params = new URLSearchParams(searchParams.toString());
    //   const searchParam = params.get("search");
    //   if (searchParam) {
    //     setIsOpen(true);
    //     const valueWithoutSymbols = searchParam.replace("+", " ");
    //     inputRef.current.value = valueWithoutSymbols;
    //     debouncedSearch(valueWithoutSymbols);
    //   }
    // }, []);
    
    // useEffect(() => {
    //   const handleClickOutside = (event: MouseEvent) => {
    //     if (
    //       inputRef.current &&
    //       !inputRef.current.contains(event.target as Node) &&
    //       wrapRef.current &&
    //       !wrapRef.current.contains(event.target as Node)
    //     ) {
    //       setIsOpen(false);
    //       setSearchItems([]);
    //       setSearchQuery("");
    //     }
    //   };
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [inputRef, wrapRef]);
    
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node) &&
          wrapRef.current &&
          !wrapRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
          // setSearchItems([]); //TODO: глянуть
          // setSearchQuery("");
        }
      };
      
      document.addEventListener("mousedown", handleClickOutside);
      if (inputRef.current) {
        inputRef.current.addEventListener("keydown", handleKeyDown);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        if (inputRef.current) {
          inputRef.current.removeEventListener("keydown", handleKeyDown);
        }
      };
    }, [inputRef, wrapRef]);
    
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (inputRef.current && inputRef.current.value) {
          router.push("/search/?query=" + inputRef.current.value);
          setIsOpen(false);
          inputRef.current.blur();
        }
      }
    };
    useEffect(() => {
      if (isOpen === false) {
        if (!searchQuery) {
          setSearchItems([]); //TODO: глянуть
          setSearchKeys([]); //TODO: глянуть
          setSearchQuery("");
        }
        document.body.style.overflow = "auto";
      }
    }, [isOpen]);
    
    useEffect(() => {
      if (
        typeof window !== "undefined" &&
        inputRef.current &&
        !window.location.search
      ) {
        inputRef.current.value = "";
        setSearchQuery("");
      }
    }, [pathName]);
    
    return (
      <SearchContextProvider>
        {/* {isOpenVoiceModal && (
         <VoiceModal
         isOpen={isOpenVoiceModal}
         onClose={() => setIsOpenVoiceModal(false)}
         voiceModalRef={voiceModalRef}
         />
         )} */}
        <div
          className={styles.wrap}
          itemProp="potentialAction"
          itemScope
          itemType="http://schema.org/SearchAction"
          ref={ref}
        >
          <meta
            itemProp="target"
            content="https://ohotaktiv.ru/search/?search={search_term_string}"
          />
          <input
            id="search-input"
            placeholder="Искать в ОхотАктив"
            className={styles.input}
            ref={inputRef}
            // value={transcript}
            onChange={(e) => handleSearchInput(e.target.value)}
            itemProp="query-input"
            name="search_term_string"
            autoComplete="off"
            onClick={() => setIsOpen(true)}
          />
          {isOpen && (
            <SearchModal
              items={searchItems}
              query={searchQuery}
              searchKeys={searchKeys}
              onClickPopular={handlePopularClick}
              // @ts-ignore
              ref={wrapRef}
              closeSearch={() => setIsOpen(false)}
              searchRedirect={searchRedirect}
              searchCatalogs={searchCatalogs}
              isFetching={isFetching}
            />
          )}
          {/* <button
           className={`${styles.searchButton} ${styles.voice}`}
           onClick={() => setIsOpenVoiceModal(true)}
           >
           <MicActiveIcon className={styles.active_mic} />
           </button> */}
          <button
            className={styles.searchButton}
            onClick={() => {
              if(inputRef.current){
                inputRef.current.focus()
              }
            }}
            title="Поиск"
          >
            <SearchIcon/>
          </button>
        </div>
      </SearchContextProvider>
    );
  }
);

HeaderSearch.displayName = "HeaderSearch";
export default HeaderSearch;
