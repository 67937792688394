import { CartIcon } from "@/components/icon";
import styles from "./header-actions-cart.module.scss";
import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useAppSelector } from "@/services/redux/hooks";
import { getUserInfo } from "@/services/redux/features/userSlice";

const HeaderActionsCart: FC = () => {
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter((item) => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return (
    <div className={styles.wrap}>
      <Link prefetch={false} className={styles.link} href="/cart/" title="Корзина">
        <CartIcon />
        <span className={styles.count}>{isClient ? countCart : 0}</span>
      </Link>
    </div>
  );
};
export default HeaderActionsCart;
