"use client";

import Link from "next/link";
import styles from "./custom-marquee.module.scss";
import { FC, useState } from "react";
import { Picture } from "../picture/picture";

interface IMarquee {
  checkSaleData: {
    success: boolean;
    auth: boolean;
    data: {
      active_to: string;
      picture_plashka: string;
      property_run_line_link: string;
      property_run_line_text: string;
      top_plashka_picture: string;
      top_plashka_picture_mobile: string;
    };
  };
}

export const CustomMarquee: FC<IMarquee> = (props) => {
  const { checkSaleData } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  if (!checkSaleData || checkSaleData.error_msg) return;
  if (checkSaleData.success === false) return;
  // const currentDate = new Date()
  //   .toLocaleDateString("en-GB")
  //   .replace(/\//g, ".");
  // if (currentDate === "09.05.2024") return;
  // if (
  //   currentDate !== "06.05.2024" &&
  //   currentDate !== "07.05.2024" &&
  //   currentDate !== "08.05.2024"
  // )
  //   return;
  return (
    <noindex>
      {/* <div
      id={"custom-marquee"}
        className={styles.wrap}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Marquee className={styles.marquee} speed={60} play={!isHovered}>
          <Link
            href={checkSaleData.data.property_run_line_link}
            className={styles.text}
          >
            {checkSaleData.data.property_run_line_text}
          </Link>
        </Marquee>
      </div> */}
      <Link
        prefetch={false}
        id={"custom-marquee"}
        className={styles.link}
        href={checkSaleData.data.property_run_line_link}
      >
        <Picture
          className={styles.picture}
          desktop={`https://ohotaktiv.ru${checkSaleData.data.top_plashka_picture}`}
          mobile={`https://ohotaktiv.ru${checkSaleData.data.top_plashka_picture_mobile}`}
          alt="Плашка с акцией"
          width={1907}
          height={64}
        />
      </Link>
    </noindex>
  );
};
