"use client";
import styles from "./header-catalog-button.module.scss";
import { BurgerIcon } from "@/components/icon";
import ButtonComponent from "@/components/button/button";
import React, { FC, ForwardedRef } from "react";
interface IHeaderCatalogButton {
  isOpen: boolean;
  setIsOpen: () => void;
  ref?: ForwardedRef<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
const HeaderCatalogButton: FC<IHeaderCatalogButton> = React.forwardRef((props, ref: any) => {
  const  { isOpen, setIsOpen , onMouseLeave, onMouseEnter} = props;
  return (
    <ButtonComponent onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={setIsOpen} className={styles.hamburger} ref={ref}>
      <>
        <BurgerIcon isActive={isOpen} className={`${styles.icon} ${isOpen && styles.active}`} />
        <>Каталог</>
      </>
    </ButtonComponent>
  );
});
HeaderCatalogButton.displayName = "HeaderCatalogButton";
export default HeaderCatalogButton;
