import styles from "./index.module.scss";
import { ICatalogMiniItem, ICatalogObjDepths } from "@/services/types/types";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useOutsideClick } from "@/services/hooks/useOutsideClick";
import Image from "next/image";
import { CloseIcon } from "@/components/icon";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import { Icon } from "@/shared/components/Icon";
import CatalogList from "@/components/CatalogModal/CatalogList";
import CatalogDetail from "@/components/CatalogModal/CatalogDetail";
import { useRouter } from "next/navigation";
import { rejectedCatalogs } from "@/shared/catalogHelpers";

interface ICatalogModalProps {
  catalog: ICatalogMiniItem[];
  visible: boolean;
  handleClose: () => void;
}

const NewCatalogModal = (props: ICatalogModalProps) => {
  const {
    catalog = [],
    visible = false,
    handleClose = () => {}
  } = props;
  const firstCatalog = catalog[0];
  const firstDepths = Object.keys(firstCatalog.depths).map((item) => firstCatalog.depths[item]);
  const [currentCatalog, setCurrentCatalog] = useState<ICatalogObjDepths[]>(firstDepths);
  const [currentCatalogInfo, setCurrentCatalogInfo] = useState<ICatalogMiniItem>(firstCatalog);
  const [catalogWithoutDepths, setCatalogWithoutDepths] = useState<boolean>(false);
  const [listWithoutDepths, setListWithoutDepths] = useState<ICatalogObjDepths[]>([]);
  const [listWithDepths, setListWithDepths] = useState<ICatalogObjDepths[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isChoice, setIsChoice] = useState<boolean>(false);
  const isMobile = UseMediaQuery("(max-width: 1024px)");
  const router = useRouter();
  
  const handleClickCatalog = (id: string, code: string = '') => {
    const findCatalog = catalog.find((item: ICatalogMiniItem) => item.id === id);
    if (!findCatalog) return;
    if (!findCatalog.depths) {
      setCurrentCatalog([]);
      setCurrentCatalogInfo(findCatalog);
      setListWithDepths([]);
      setCatalogWithoutDepths(false);
      setListWithoutDepths([]);
      if (isMobile) {
        router.push(`/catalog/${code}/`);
        handleClose();
      }
      if (!loading) setLoading(true);
      return;
    } else {
      const newDepths = Object.keys(findCatalog.depths).map((item) => {
        return findCatalog.depths[item];
      });
      const withoutDepths = newDepths.every((item) => !item.depths);
      const withoutDepthsFilter = newDepths.filter((item) => !item.depths);
      const withDepths = newDepths
        .filter((item) => item.depths)
        .sort((a, b) => {
          const depthA = a.depths ? Object.keys(a.depths).length : 0;
          const depthB = b.depths ? Object.keys(b.depths).length : 0;
          return depthB - depthA;
        });
      const sortedDepths = [...withoutDepthsFilter, ...withDepths];
      setListWithDepths(withDepths);
      setCatalogWithoutDepths(withoutDepths);
      setListWithoutDepths(withoutDepthsFilter);
      setCurrentCatalogInfo(findCatalog);
      if (isMobile) setIsChoice(true);
      setCurrentCatalog(sortedDepths);
      if (!loading) setLoading(true);
      return;
    }
  };
  
  useOutsideClick(containerRef.current, handleClose);
  
  const handleBack = () => {
    if (!isChoice) {
      handleClose();
      return;
    }
    setIsChoice(false);
    setCurrentCatalog(firstDepths);
    setCurrentCatalogInfo(firstCatalog);
  };
  
  useEffect(() => {
    if (!loading) {
      handleClickCatalog(firstCatalog.id, firstCatalog.code);
    }
    if (visible) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [visible]);
  
  return (
    <>
      <div
        className={`${styles.catalog_wrap} ${visible ? styles.visible : "visually-hidden"}`}
        ref={containerRef}
      >
        <div className={styles.catalog_header}>
          {isMobile ? (
            <button className={styles.back_button} onClick={handleBack}>
              <Icon name={'chevron'} className={styles.icon}/>
              {isChoice ? "Все категории" : "Категории"}
            </button>
          ) : null}
          <button onClick={handleClose} className={styles.close}><CloseIcon/></button>
        </div>
        <div className={styles.content}>
          <CatalogList
            catalog={catalog}
            rejectedCatalogs={rejectedCatalogs}
            isChoice={isChoice}
            handleClickCatalog={handleClickCatalog}
            currentCatalogCode={currentCatalogInfo.code}
          />
          <CatalogDetail
            currentCatalogInfo={currentCatalogInfo}
            isChoice={isChoice}
            currentCatalog={currentCatalog}
            noDepths={catalogWithoutDepths}
            listWithoutDepths={listWithoutDepths}
            listWithDepths={listWithDepths}
            handleClose={handleClose}
            rejectedCatalogs={rejectedCatalogs}
          />
        </div>
      </div>
      {visible ? (
        <div className={styles.shadow}/>
      ) : null}
    </>
  );
};

export default NewCatalogModal;