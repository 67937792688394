"use client";

import styles from "./header-menu-list.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { CatalogIcon, TelHeaderIcon, WorktimeIcon } from "@/components/icon";
import { GeoMenuButton } from "./geo-menu-button/geo-menu-button";
import { ProfileMenuButton } from "./profile-menu-button/profile-menu-button";
import { CartMenuButton } from "./cart-menu-button/cart-menu-button";
import { StatusMenuButton } from "./status-menu-button/status-menu-button";
import { CallbackMenuButton } from "./callback-menu-button/callback-menu-button";

interface IHeaderMenuList {
  closeMenu: () => void;
}

type Position = {
  top: number,
  left: number
}

export const HeaderMenuList: FC<IHeaderMenuList> = ({closeMenu}) => {
  const headerRef = useRef(document.getElementsByTagName("header")[0]);
  const [position, setPosition] = useState<Position>({top: 0, left: 0});
  
  const dropIn = {
    hidden: {
      transform: "translateX(-100%)",
      opacity: 0,
    },
    visible: {
      transform: "translateX(0)",
      opacity: 1,
    },
    exit: {
      transform: "translateX(-100%)",
      opacity: 0,
    },
  };
  const ref = useRef(null);
  
  useEffect(() => {
    const updatePosition = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left,
        });
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className={styles.wrap}
        ref={ref}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
        style={{
          // top: headerRef.current.offsetHeight + marqueeRef.current.offsetHeight,
          top: position.top + 66,
        }}
        itemScope
        itemType="http://schema.org/SiteNavigationElement"
      >
        <ul className={styles.list}>
          <li className={styles.item}>
            <GeoMenuButton/>
          </li>
          <li className={styles.item}>
            <ProfileMenuButton/>
          </li>
          <li className={styles.item}>
            <CartMenuButton/>
          </li>
          <li className={styles.item} onClick={closeMenu}>
            <Link prefetch={false} href="/catalog/" className={styles.button}>
              <CatalogIcon/>
              Каталог
            </Link>
          </li>
          <li className={styles.item}>
            <StatusMenuButton/>
          </li>
          <li className={styles.item}>
            <CallbackMenuButton/>
          </li>
          <li className={styles.item}>
            <Link
              prefetch={false}
              href="tel:88007008256"
              className={styles.button}
              onClick={() => ym(42989679, "reachGoal", "clickOnTel")}
            >
              <TelHeaderIcon/>8 800 700-82-56
            </Link>
          </li>
          <li className={styles.item}>
            <p className={styles.button}>
              <WorktimeIcon/>
              Режим работы 7:00 - 22:00 (по МСК)
            </p>
          </li>
        </ul>
        <ul
          className={styles.list}
          itemProp="about"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/remington/" className={styles.button} itemProp="url">
              Remington
            </Link>
            <meta itemProp="name" content="Remington"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/sales/" className={styles.button} itemProp="url">
              Акции
            </Link>
            <meta itemProp="name" content="Акции"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/new/" className={styles.button} itemProp="url">
              Новинки
            </Link>
            <meta itemProp="name" content="Новинки"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/liquidation/" className={styles.button} itemProp="url">
              Ликвидация
            </Link>
            <meta itemProp="name" content="Ликвидация"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/shops/" className={styles.button} itemProp="url">
              Магазины
            </Link>
            <meta itemProp="name" content="Магазины"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/blog/" className={styles.button} itemProp="url">
              Блог
            </Link>
            <meta itemProp="name" content="Блог"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/opt/" className={styles.button} itemProp="url">
              Опт
            </Link>
            <meta itemProp="name" content="Опт"/>
          </li>
          <li
            className={styles.item}
            onClick={closeMenu}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link prefetch={false} href="/partners/" className={styles.button} itemProp="url">
              Поставщикам
            </Link>
            <meta itemProp="name" content="Поставщикам"/>
          </li>
        </ul>
      </motion.div>
    </AnimatePresence>
  );
};
