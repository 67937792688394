"use client";
import styles from "./header-dropdown.module.scss";
import { FC, useState } from "react";
import { Dropdown } from "./dropdown/dropdown";
import { StatusOrderPopup } from "@/components/popups";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { ChevronIcon } from "@/components/icon";

interface IHeaderDropdown {}

export const HeaderDropdown: FC<IHeaderDropdown> = () => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return (
    <>
      {showModal && (
        <StatusOrderPopup isOpen={showModal} onClose={handleCloseModal} />
      )}
      <div className={styles.dropdown}>
        <button
          onClick={() => setOpenDropdown(!isOpenDropdown)}
          className={styles.dropdownButton}
        >
          Ещё{" "}
          <ChevronIcon
            className={isOpenDropdown ? styles.chevronActive : styles.chevron}
          />
        </button>
        {isOpenDropdown && (
          <Dropdown
            setOpenDropdown={setOpenDropdown}
            openStatusPopup={handleOpenModal}
          />
        )}
      </div>
    </>
  );
};
