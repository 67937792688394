import { ArrowBackIcon, BurgerIcon, CloseIcon } from "@/components/icon";
import styles from "./header-menu.module.scss";
import { FC } from "react";
import { HeaderMenuList } from "./header-menu-list/header-menu-list";
import { usePathname, useRouter } from "next/navigation";

interface IHeaderMenu {
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpenMenu: boolean) => void;
}

export const HeaderMenu: FC<IHeaderMenu> = ({ isOpenMenu, setIsOpenMenu }) => {
  const pathname = usePathname();
  const router = useRouter();
  const handleBack = () => {
    if (isOpenMenu) {
      setIsOpenMenu(!isOpenMenu);
    } else {
      router.back();
    }
  };
  return (
    <>
      <div className={styles.menu}>
        {pathname === "/" ? (
          <button
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            className={styles.button}
          >
            {isOpenMenu ? (
              <CloseIcon className={styles.icon} />
            ) : (
              <BurgerIcon className={styles.icon} />
            )}
          </button>
        ) : (
          <button onClick={handleBack} className={styles.button}>
            {isOpenMenu ? (
              <CloseIcon className={styles.icon} />
            ) : (
              <ArrowBackIcon className={styles.icon} />
            )}
          </button>
        )}
      </div>
      {isOpenMenu && <HeaderMenuList closeMenu={() => setIsOpenMenu(false)} />}
    </>
  );
};
