import { request } from "../functions/request";

export function getSearchStarted() {
  return request("/search/");
}

export function getSearchItems(value: string) {
  return request(`/search/?search=${value}`);
}

export function getSearchCatalog(value: string, sectionCatalog?: string) {
  // TODO: &stock={stock}
  // TODO: &sort[SORT]=ASC (это по популярности в поиске) (по умолчанию будет по релевантности )
  // return request(`/search/?search=${value}&type=catalog`);
  return request(`/catalog/get_items_list.php?search=${value}${sectionCatalog ? `&section=${sectionCatalog}` : ``}&price_check=true`).then(res => {
    if (res.items) {
      return res.items
    }
  });
}

export function getSearchSubsections(value: string, section?: string) {
  return request(`/catalog/get_sub_section.php?search=${value}${section ? `&section=${section}` : ``}`);
}

export function getSearchFilters(value: string) {
  return request(`/catalog/get_filter.php?search=${value}`);
}
