import HeaderActionsCart from "./header-actions-cart/header-actions-cart";
import { HeaderActionsChat } from "./header-actions-chat/header-actions-chat";
import HeaderActionsFavourite from "./header-actions-favourite/header-actions-favourite";
import HeaderActionsProfile from "./header-actions-profile/header-actions-profile";
import styles from "./header-actions.module.scss";
import { FC } from "react";

const HeaderActions: FC = () => {

  return (
    <div className={styles.wrap}>
      <HeaderActionsProfile />
      <HeaderActionsCart />
      <HeaderActionsFavourite />
      <HeaderActionsChat />
    </div>
  );
};
export default HeaderActions;
