"use client";
import styles from "./header-geo.module.scss";
import { GeoIcon } from "@/components/icon";
import { FC } from "react";
import { GeoPopup } from "@/components/popups";
import { useOpenModal } from "@/services/hooks/useOpenModal";

interface IHeaderGeo {
  city?: string;
}
const HeaderGeo: FC<IHeaderGeo> = (props) => {
  const { city = '' } = props;
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();

  return (
    <>
      {showModal && <GeoPopup isOpen={showModal} onClose={handleCloseModal} />}
      <div className={styles.geo}>
        <GeoIcon />
        <button className={styles.button} onClick={handleOpenModal}>
          {city ? JSON.parse(city).name : "Москва"}
        </button>
      </div>
    </>
  );
};
export default HeaderGeo;
