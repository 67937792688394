import { FC, useRef } from "react";
import styles from "./dropdown.module.scss";
import { useOutsideClick } from "@/services/hooks/useOutsideClick";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";

export const Dropdown: FC<{
  setOpenDropdown: (argument: boolean) => void;
  openStatusPopup: () => void;
}> = ({ setOpenDropdown, openStatusPopup }) => {
  const dropIn = {
    hidden: {
      top: "17px",
      opacity: 0,
    },
    visible: {
      top: "22px",
      opacity: 1,
    },
    exit: {
      top: "17px",
      opacity: 0,
    },
  };
  const ref = useRef(null);
  useOutsideClick(ref.current, () => setOpenDropdown(false));
  return (
    <AnimatePresence>
      <motion.ul
        className={styles.dropdownList}
        ref={ref}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
      >
        <li className={styles.dropdownItem}>
          <Link prefetch={false} href="/liquidation/" className={styles.dropdownLink}>
            Ликвидация
          </Link>
        </li>
        <li className={styles.dropdownItem}>
          <Link prefetch={false} href="/shops/" className={styles.dropdownLink}>
            Магазины
          </Link>
        </li>
        <li className={styles.dropdownItem}>
          <Link prefetch={false} href="/blog/" className={styles.dropdownLink}>
            Блог
          </Link>
        </li>
        <li className={styles.dropdownItem}>
          <Link prefetch={false} href="/partners/" className={styles.dropdownLink}>
            Поставщикам
          </Link>
        </li>
        <li className={styles.dropdownItem}>
          <Link prefetch={false} href="/opt/" className={styles.dropdownLink}>
            Опт
          </Link>
        </li>
        <li className={styles.dropdownItem}>
          <button onClick={openStatusPopup} className={styles.dropdownStatus}>
            Статус заказа
          </button>
        </li>
      </motion.ul>
    </AnimatePresence>
  );
};
