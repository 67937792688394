"use client"
import React, { useEffect, useState } from 'react';

interface LazySvgProps extends React.ComponentProps<'svg'> {
  name: string;
}

export const Icon = ({ name, ...props }: LazySvgProps) => {
  const [IconComponent, setIconComponent] = useState<React.ComponentType | null>(null);
  
  useEffect(() => {
    import(`@/public/images/icons/${name}.svg`)
      .then((mod) => {
        setIconComponent(() => mod.default); // Убедитесь, что возвращается default экспорт
      })
      .catch((error) => {
        console.error(`Error loading SVG: ${name}`, error);
      });
  }, [name]);
  
  if (!IconComponent) {
    return null;
  }
  
  return <IconComponent {...props} />;
};
