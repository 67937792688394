import { CallbackIcon } from "@/components/icon";
import styles from "./callback-menu-button.module.scss";
import { FC } from "react";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { CallbackPopup } from "@/components/popups/callback-popup/callback-popup";

interface ICallbackMenuButton {}

export const CallbackMenuButton: FC<ICallbackMenuButton> = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return (
    <>
      {showModal && (
        <CallbackPopup isOpen={showModal} onClose={handleCloseModal} />
      )}
      <button onClick={handleOpenModal} className={styles.button}>
        <CallbackIcon />
        Обратный звонок
      </button>
    </>
  );
};
