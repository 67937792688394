import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-footer/app-footer.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/app-header.module.scss");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-bottom/header-bottom.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-geo/header-geo.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-info/header-info.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-links-list/header-dropdown/header-dropdown.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-links-list/header-links-list.module.scss");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/app-header/header-status/header-status.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/marquee/custom-marquee.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/toast/toast.module.scss");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/sale-button/sale-button.module.scss");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/utils/functions/request-client.ts")