import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const PersonalIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="11.9997"
        cy="8.21061"
        r="4.21061"
        stroke="#1D1D1D"
        strokeWidth="2"
      />
      <path
        d="M4.87966 16.0942C6.5338 13.996 9.12193 12.5262 11.9978 12.5262C14.8737 12.5262 17.4618 13.996 19.1159 16.0942C19.5008 16.5824 19.6243 17.0296 19.6149 17.4115C19.6053 17.802 19.4539 18.2081 19.1495 18.5949C18.5277 19.3847 17.3292 20 15.9453 20L8.05027 20C6.66636 20 5.46785 19.3847 4.84611 18.5949C4.54168 18.2081 4.39033 17.802 4.38068 17.4115C4.37124 17.0296 4.49478 16.5824 4.87966 16.0942Z"
        stroke="#1D1D1D"
        strokeWidth="2"
      />
    </svg>
  );
};
