"use client";
import styles from "./search-cards-list.module.scss";
import { FC } from "react";
import { ArrowIcon } from "@/components/icon";
import Link from "next/link";
import { ICardMini } from "@/services/types/types";
import { SearchCard } from "./search-card/search-card";

interface ISearchCardsList {
  items: Array<ICardMini>;
  showButton?: boolean;
  query: string;
  onClick?: () => void;
  cardMiniDisable?: boolean;
  searchRedirect?: string;
}
export const SearchCardsList: FC<ISearchCardsList> = ({
  items,
  showButton,
  query,
  onClick,
  cardMiniDisable,
  searchRedirect,
}) => {
  const handleCardClick = () => {
    if (onClick) {
      onClick();
    }
    ym(42989679, "reachGoal", "goingToCardFromQuickSearch");
  };
  return (
    <div className={styles.wrap}>
      <div className={styles.cardsList}>
        {items.length > 0 ? (
          items.map((item, index: number) => {
            if (index > 2) return;
            return (
              <SearchCard
                card={item}
                key={`${index + "SearchCardsList"}`}
                onClose={handleCardClick}
              />
            );
          })
        ) : (
          <p className={styles.text}>По запросу «{query}» ничего не найдено</p>
        )}
      </div>
      {showButton && (
        <Link
          prefetch={false}
          onClick={onClick}
          className={styles.link}
          href={searchRedirect ? searchRedirect : `/search/?query=${query}`}
          aria-disabled={cardMiniDisable}
        >
          Смотреть все результаты <ArrowIcon className={styles.icon} />
        </Link>
      )}
    </div>
  );
};
