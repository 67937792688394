import Link from "next/link";
import Logo from "../../../assets/images/logo-header.webp";
import styles from "./header-logo.module.scss";
import { FC } from "react";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { scrollToTop } from "@/utils/functions/scrollToTop";

const HeaderLogo: FC = () => {
  const pathname = usePathname();
  const router = useRouter();
  const handleScroll = () => {
    router.refresh();
    scrollToTop();
  };
  return (
    <div className={styles.logoWrap}>
      {pathname !== "/" ? (
        <Link prefetch={false} href="/" className={styles.link}>
          <Image
            className={styles.logo}
            alt="Интернет-магазин товаров для охоты и рыбалки ОхотАктив"
            title="Интернет-магазин товаров для охоты и рыбалки ОхотАктив"
            src={Logo}
          />
        </Link>
      ) : (
        <button className={styles.link} onClick={handleScroll}>
          <Image
            className={styles.logo}
            alt="Интернет-магазин товаров для охоты и рыбалки ОхотАктив"
            title="Интернет-магазин товаров для охоты и рыбалки ОхотАктив"
            src={Logo}
          />
        </button>
      )}
    </div>
  );
};

export default HeaderLogo;
