import styles from "./index.module.scss";
import Image from "next/image";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import { ICatalogDepths, ICatalogDetailBanners, ICatalogMiniItem, ICatalogObjDepths } from "@/services/types/types";
import DetailElem from "@/components/CatalogModal/CatalogDetail/DetailElem";
import Link from "next/link";
import CatalogBanner from "@/components/CatalogModal/CatalogDetail/CatalogBanner";
import { useEffect, useState } from "react";
import * as crypto from "node:crypto";
import { Icon } from "@/shared/components/Icon";

interface ICatalogDetail {
  isChoice: boolean;
  currentCatalogInfo: ICatalogMiniItem;
  currentCatalog: ICatalogObjDepths[];
  noDepths: boolean;
  listWithoutDepths: ICatalogObjDepths[];
  listWithDepths: ICatalogObjDepths[];
  handleClose: () => void;
  rejectedCatalogs: string[];
  banners?: ICatalogDetailBanners[];
}

const CatalogDetail = (props: ICatalogDetail) => {
  
  const {
    isChoice,
    currentCatalogInfo,
    currentCatalog,
    noDepths = false,
    listWithoutDepths,
    listWithDepths,
    handleClose = () => {},
    rejectedCatalogs,
  } = props;
  
  const isMobile = UseMediaQuery("(max-width: 1024px)");
  const isReject = rejectedCatalogs.includes(currentCatalogInfo.code);
  const banners = currentCatalogInfo.catalog_banners && currentCatalogInfo.catalog_banners.length ?
    currentCatalogInfo.catalog_banners : [];

  return (
    <div className={`${styles.catalog_detail} ${isChoice ? styles.choice : ''}`}>
      <div className={styles.catalog_content}>
        {!isMobile ? /* null */
          (
            <Link
              href={`/catalog/${currentCatalogInfo.code}`}
              className={styles.hero_link}
              onClick={handleClose}
            >
              {!isReject ? (
                <p className={styles.hero_link_name}>{currentCatalogInfo.name}</p>
              ) : null}
              <Image
                src={`https://ohotaktiv.ru${currentCatalogInfo.modal_picture}`}
                alt={`Изображение к каталогу: ${currentCatalogInfo.name}`}
                width={400}
                height={80}
                className={styles.hero_link_image}
              />
              {!currentCatalogInfo.depths ? (
                <span className={styles.chevron}>
                  <Icon name={"chevron"} className={styles.chevron_icon}/>
                </span>
              ) : null}
            </Link>
          )
          : (
            <Link
              href={`/catalog/${currentCatalogInfo.code}`}
              className={styles.catalog_detail_name}
              onClick={handleClose}
            >
              {currentCatalogInfo.name}
            </Link>
          )}
        <div className={styles.catalogs}>
          {listWithoutDepths.length && !isMobile ? (
            <div
              className={`
                ${styles.catalog_detail_list}
                ${!isMobile && noDepths ? styles.catalog_detail__no_depth : ''}
              `}
            >
              {listWithoutDepths.map((item, index: number) => {
                return (
                  <DetailElem
                    key={index + `_catalog_detail_elem`}
                    catalog={item}
                    newDepths={[]}
                    handleClose={handleClose}
                  />
                );
              })}
            </div>
          ) : null}
          {!isMobile && listWithDepths.length ? (
            <div
              className={`${styles.catalog_detail_list}`}
            >
              {listWithDepths.map((item, index: number) => {
                const newDepths = item.depths ? Object.keys(item.depths).map((depItem: string) => {
                  if (item.depths) return item.depths[depItem];
                  else return false;
                }) : false;
                return (
                  <DetailElem
                    key={index + `_catalog_detail_elem`}
                    catalog={item}
                    newDepths={newDepths}
                    handleClose={handleClose}
                  />
                );
              })}
            </div>
          ) : null}
          {isMobile && !!currentCatalog.length ? (
            <div
              className={`${styles.catalog_detail_list} ${!isMobile && noDepths ? styles.catalog_detail__no_depth : ''}`}
            >
              {currentCatalog.map((item, index: number) => {
                const newDepths = item.depths ? Object.keys(item.depths).map((depItem: string) => {
                  if (item.depths) return item.depths[depItem];
                  else return false;
                }) : false;
                return (
                  <DetailElem
                    key={index + `_catalog_detail_elem`}
                    catalog={item}
                    newDepths={newDepths}
                    handleClose={handleClose}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      {!!banners.length ? (
        <div className={styles.catalog_banners}>
          {banners.map((item, index: number) => (
            <CatalogBanner
              banner={item}
              key={index + "_catalogBanner"}
              handleClose={handleClose}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CatalogDetail;